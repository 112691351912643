<template>
  <v-hover v-slot="{ hover }" class="favorite-video-card">
    <v-layout row gap-xs-2
        class="favorite-video-card pa-0 elevation-0"
        :class="{
                'elevation-12': hover && favorite && favorite.video && vimeoData,
                'loaded': favorite && favorite.video && vimeoData,
              }"
        @click="onSelect">
        <template v-if="favorite && favorite.video && vimeoData" >
          <v-flex xs8 py-2 pl-2>
            <h5 class="starling-h5 primary--text" style="white-space: nowrap;  overflow: hidden;  text-overflow: ellipsis;">
            {{ favorite.video.title || vimeoData.title }}
            </h5>
            <p class="starling-text">
              <strong>{{ $t('app.favorites.types.VIDEO') }}</strong> ~ {{ formatDuration(vimeoData.duration) }} {{ formatDuration(vimeoData.duration) >1 ? 'mins' : 'min' }}
            </p>
          </v-flex>
          <v-flex xs4>
            <v-layout row fill-height>
              <v-flex xs12 class="thumbnail-container">
                <img v-if="vimeoData" :src="vimeoData.thumbnail_url" class="thumbnail rounded-image" />
              </v-flex>
            </v-layout>
          </v-flex>
        </template>
        <template v-else>
          <v-flex xs12 class="text-center">
            <v-progress-circular :size="55" color="primary" indeterminate/>
          </v-flex>
        </template>
    </v-layout>
  </v-hover>
</template>

<script>
export default {
  name: 'favorite-video-card',
  props: {
    favorite: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      vimeoData: null,
    };
  },
  watch: {
    favorite: {
      immediate: true,
      handler(newVal) {
        this.thumbnailUrl = null;
        this.vimeoData = null;
        this.$http.get(`https://vimeo.com/api/oembed.json?url=https://vimeo.com/${newVal.video.vimeoId}`)
          .then(res => {
            this.vimeoData = res.data;
          });
      },
    },
  },
  methods: {
    formatDuration(time) {
      const minutes = Math.floor(time / 60);
      if (minutes === 0) return 1;
      return minutes;
    },
    onSelect() {
      if (!(this.favorite && this.favorite.video && this.vimeoData)) return;
      this.$emit('selected', { favorite: this.favorite, vimeoData: this.vimeoData });
    },
  },
};
</script>

<style scoped lang="scss">
.favorite-video-card {
  border-radius: 15px;
  border: 3px var(--StarlingLightGrey) solid;
  .thumbnail-container {
    position: relative;
    .thumbnail {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.favorite-video-card.loaded {
  cursor: pointer;
}

.favorite-card-graphic img {
  vertical-align: top;
}

.favorite-video-card-content {
  position: relative;
}
.rounded-image {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

</style>
