<template>
<v-layout row wrap pt-3 gap-xs-3 class="favorites-card">
  <v-flex xs12>
    <h1 class="starling-h1">{{ $t('app.favorites.title') }}</h1>
  </v-flex>

  <v-flex xs12>
    <h3 class="starling-body">{{ $t('app.favorites.content') }}</h3>
  </v-flex>

  <template v-if="!hasFavorites">
    <v-flex xs12>
      <empty-favorite-card/>
    </v-flex>

    <v-flex xs12>
      <v-layout class="element-instructional">
        <v-flex shrink>
          <svg width="40" height="39" viewBox="0 0 40 39" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M39.9079 0.196087C39.9079 0.196087 38.2185 4.4952 35.5348 5.79672C32.8511 7.09823 30.9629 6.84765 29.2735 7.82192C27.5841 8.79618 25.0993 10.1968 23.5084 14.9952C23.5084 14.9952 17.8437 3.49475 11.1847 7.59565C3.72648 12.1884 9.39492 19.3953 11.8797 21.2952C11.8797 21.2952 17.0278 25.5943 18.9253 27.195C20.8247 28.7958 23.4081 32.4946 19.3324 38.0952C19.3324 38.0952 15.5559 32.1954 7.46951 26.1946C1.97019 22.1143 -3.62577 12.3941 3.03326 4.89351C9.69228 -2.60703 18.7358 -0.50703 23.2092 4.69342C23.2092 4.69342 27.086 -0.183522 34.1409 0.00534747C41.1958 0.194217 39.9079 0.196087 39.9079 0.196087Z"
                fill="url(#instructional-linear)"/>
            <defs>
              <linearGradient id="instructional-linear" x1="0.00203726" y1="19.0489" x2="40.0004"
                              y2="19.0489" gradientUnits="userSpaceOnUse">
                <stop stop-color="var(--button-gradient-2)"/>
                <stop offset="1" stop-color="var(--button-gradient-1)"/>
              </linearGradient>
            </defs>
          </svg>
        </v-flex>
        <v-flex shrink>
          <i18n path="app.favorites.empty_content.text" tag="div" class="starling-instructional">
            <router-link :to="{name:'videos'}" class="font-weight-bold">
              {{ $t('app.favorites.empty_content.link') }}
            </router-link>
          </i18n>
        </v-flex>
      </v-layout>
    </v-flex>
  </template>

  <v-flex xs12 v-else>
    <v-layout row wrap gap-xs-2>
      <v-flex xs12 v-for="favorite in favorites" :key="favorite.id">
        <favorite-video-card v-if="favorite.type === 'VIDEO'" :favorite="favorite" @selected="onSelected($event)"/>
        <favorite-audio-card v-if="favorite.type === 'MEDIA' && favorite.media.type ==='AUDIO'" :favorite="favorite" @selected="onSelected($event)"/>
      </v-flex>
    </v-layout>
  </v-flex>

  <!-- Modal -->
  <v-dialog fullscreen v-model="displayModal" width='100%'>
    <v-card v-if="selected && displayModal">
      <v-card-actions class="pb-0">
        <v-spacer></v-spacer>
        <v-icon @click="closeModal">close</v-icon>
      </v-card-actions>
      <v-layout align-center justify-center pb-5>
        <v-flex xs10 sm8 xl6>
          <v-layout row wrap gap-xs-3>
            <template v-if="selected.favorite.type === 'VIDEO'">
              <v-flex xs12>
                <h3 class="starling-h3">  {{ selected.favorite.video.title || selected.vimeoData.title }}</h3>
              </v-flex>
              <v-flex xs12>
                <vimeo-video :videoId="selected.favorite.video.vimeoId"
                            :identifier="selected.favorite.video.identifier"
                            :transcript="selected.favorite.video.transcript"
                            @videoEvent="onVideoEvent"/>
              </v-flex>
            </template>

            <template v-if="selected.favorite.type === 'MEDIA' && selected.favorite.media.type ==='AUDIO'">
              <v-flex xs12>
                <h3 class="starling-h3 mb-2"> {{ selected.favorite.media.title }}</h3>
              </v-flex>
              <v-flex xs12>
                <audio-player :audioURL="selected.favorite.media.url"
                              :transcript="selected.favorite.media.transcript"
                              :identifier="selected.favorite.media.identifier"/>
              </v-flex>
            </template>
            <v-flex xs12 text-center pt-3>
              <primary-button large @click="closeModal">
                {{ $t('common.actions.done') }}
              </primary-button>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card>
  </v-dialog>
</v-layout>
</template>

<script>
import EmptyFavoriteCard from '@/views/favorites/empty-favorite-card.vue';
import FavoriteVideoCard from '@/views/favorites/favorite-video-card.vue';
import FavoriteAudioCard from '@/views/favorites/favorite-audio-card.vue';
import { mapState } from 'vuex';
import VimeoVideo from '@/views/components/utilities/vimeo-video.vue';
import AudioPlayer from '@/views/components/utilities/audio-player.vue';
import PrimaryButton from '@/views/components/button/primary-button.vue';

export default {
  name: 'Favorites',
  components: {
    AudioPlayer,
    FavoriteAudioCard,
    VimeoVideo,
    EmptyFavoriteCard,
    FavoriteVideoCard,
    PrimaryButton,
  },
  data() {
    return {
      displayModal: false,
      selected: null,
    };
  },
  computed: {
    ...mapState('favorites', [ 'favorites' ]),
    hasFavorites() {
      return this.favorites.length;
    },
  },
  methods: {
    onSelected(selected) {
      this.displayModal = true;
      this.selected = selected;
    },
    closeModal() {
      this.displayModal = false;
      this.selected = null;
    },
    onVideoEvent(event) {
      this.$store.dispatch('therapy/watchVideoEvent', { videoId: this.selected.favorite.video.vimeoId, status: event });
    },
  },
};
</script>
