var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "empty-favorite-card", attrs: { flat: "", elevation: "0" } },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-layout",
            [
              _c("v-flex", [
                _c("p", { staticClass: "starling-body text--disabled" })
              ]),
              _c(
                "v-flex",
                { staticClass: "text-right" },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "StarlingLightGrey--text",
                      attrs: { large: "" }
                    },
                    [_vm._v("$vuetify.icons.starling_favorite")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }