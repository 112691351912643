var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-hover", {
    staticClass: "favorite-video-card",
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var hover = ref.hover
          return [
            _c(
              "v-layout",
              {
                staticClass: "favorite-video-card pa-0 elevation-0",
                class: {
                  "elevation-12":
                    hover &&
                    _vm.favorite &&
                    _vm.favorite.video &&
                    _vm.vimeoData,
                  loaded: _vm.favorite && _vm.favorite.video && _vm.vimeoData
                },
                attrs: { row: "", "gap-xs-2": "" },
                on: { click: _vm.onSelect }
              },
              [
                _vm.favorite && _vm.favorite.video && _vm.vimeoData
                  ? [
                      _c(
                        "v-flex",
                        { attrs: { xs8: "", "py-2": "", "pl-2": "" } },
                        [
                          _c(
                            "h5",
                            {
                              staticClass: "starling-h5 primary--text",
                              staticStyle: {
                                "white-space": "nowrap",
                                overflow: "hidden",
                                "text-overflow": "ellipsis"
                              }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.favorite.video.title ||
                                      _vm.vimeoData.title
                                  ) +
                                  "\n          "
                              )
                            ]
                          ),
                          _c("p", { staticClass: "starling-text" }, [
                            _c("strong", [
                              _vm._v(
                                _vm._s(_vm.$t("app.favorites.types.VIDEO"))
                              )
                            ]),
                            _vm._v(
                              " ~ " +
                                _vm._s(
                                  _vm.formatDuration(_vm.vimeoData.duration)
                                ) +
                                " " +
                                _vm._s(
                                  _vm.formatDuration(_vm.vimeoData.duration) > 1
                                    ? "mins"
                                    : "min"
                                ) +
                                "\n          "
                            )
                          ])
                        ]
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs4: "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", "fill-height": "" } },
                            [
                              _c(
                                "v-flex",
                                {
                                  staticClass: "thumbnail-container",
                                  attrs: { xs12: "" }
                                },
                                [
                                  _vm.vimeoData
                                    ? _c("img", {
                                        staticClass: "thumbnail rounded-image",
                                        attrs: {
                                          src: _vm.vimeoData.thumbnail_url
                                        }
                                      })
                                    : _vm._e()
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  : [
                      _c(
                        "v-flex",
                        { staticClass: "text-center", attrs: { xs12: "" } },
                        [
                          _c("v-progress-circular", {
                            attrs: {
                              size: 55,
                              color: "primary",
                              indeterminate: ""
                            }
                          })
                        ],
                        1
                      )
                    ]
              ],
              2
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }