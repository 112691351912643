var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-hover", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var hover = ref.hover
          return [
            _c(
              "v-card",
              {
                staticClass: "favorite-audio-card pa-0 elevation-0",
                class: {
                  "elevation-12":
                    hover && _vm.favorite && _vm.favorite.media && _vm.metadata,
                  loaded: _vm.favorite && _vm.favorite.media && _vm.metadata
                },
                on: { click: _vm.onSelect }
              },
              [
                _vm.favorite && _vm.favorite.media && _vm.metadata
                  ? _c(
                      "v-layout",
                      {
                        staticClass: "favorite-audio-card-content",
                        attrs: { "fill-height": "", "align-center": "" }
                      },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs8: "", "py-2": "", "pl-2": "" } },
                          [
                            _c(
                              "h5",
                              {
                                staticClass: "starling-h5 primary--text",
                                staticStyle: {
                                  "white-space": "nowrap",
                                  overflow: "hidden",
                                  "text-overflow": "ellipsis"
                                }
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.favorite.media.title) +
                                    "\n        "
                                )
                              ]
                            ),
                            _c("p", { staticClass: "mb-0 starling-text" }, [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(_vm.$t("app.favorites.types.AUDIO"))
                                )
                              ]),
                              _vm._v(
                                " ~ " +
                                  _vm._s(
                                    _vm.formatDuration(_vm.metadata.duration)
                                  ) +
                                  " " +
                                  _vm._s(
                                    _vm.formatDuration(_vm.metadata.duration) >
                                      1
                                      ? "mins"
                                      : "min"
                                  )
                              )
                            ])
                          ]
                        ),
                        _c(
                          "v-flex",
                          { staticClass: "text-center", attrs: { xs4: "" } },
                          [
                            _c(
                              "v-img",
                              {
                                attrs: { contain: "", "aspect-ratio": 16 / 7 }
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticStyle: { height: "100%" },
                                    attrs: { color: "primary" }
                                  },
                                  [_vm._v("$vuetify.icons.starling_audio")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _c(
                      "v-layout",
                      {
                        staticClass: "favorite-card-content",
                        attrs: { "fill-height": "", "align-center": "" }
                      },
                      [
                        _c(
                          "v-flex",
                          { staticClass: "text-center", attrs: { xs12: "" } },
                          [
                            _c("v-progress-circular", {
                              attrs: {
                                size: 55,
                                color: "primary",
                                indeterminate: ""
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }