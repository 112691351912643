<template>
  <v-card class="empty-favorite-card" flat elevation="0">
    <v-card-text>
      <v-layout>
        <v-flex>
          <p class="starling-body text--disabled"></p>
        </v-flex>
        <v-flex class="text-right">
          <v-icon large class="StarlingLightGrey--text">$vuetify.icons.starling_favorite</v-icon>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'empty-favorite-card',
};
</script>

<style scoped>
.empty-favorite-card {
  border-radius: 15px;
  border-style: dashed;
  border-width: 4px;
  border-color: var(--StarlingLightGrey);
  cursor: default;
  min-height:100px;
}
</style>
