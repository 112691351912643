<template>
  <v-hover v-slot="{ hover }">
    <v-card
        class="favorite-audio-card pa-0 elevation-0"
        :class="{
                'elevation-12': hover && favorite && favorite.media && metadata,
                'loaded': favorite && favorite.media && metadata,
              }"
        @click="onSelect">
      <v-layout fill-height align-center class="favorite-audio-card-content"
                v-if="favorite && favorite.media && metadata">
        <v-flex xs8 py-2 pl-2>
          <h5 class="starling-h5 primary--text" style="white-space: nowrap;  overflow: hidden;  text-overflow: ellipsis;">
            {{ favorite.media.title }}
          </h5>
          <p class="mb-0 starling-text"><strong>{{ $t('app.favorites.types.AUDIO') }}</strong> ~ {{ formatDuration(metadata.duration) }} {{ formatDuration(metadata.duration) >1 ? 'mins' : 'min' }}</p>
        </v-flex>
        <v-flex xs4 class="text-center">
          <v-img contain :aspect-ratio="16/7">
            <v-icon style="height: 100%;" color="primary">$vuetify.icons.starling_audio</v-icon>
          </v-img>
        </v-flex>
      </v-layout>
      <v-layout fill-height align-center class="favorite-card-content" v-else>
        <v-flex xs12 class="text-center">
          <v-progress-circular :size="55" color="primary" indeterminate/>
        </v-flex>
      </v-layout>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: 'favorite-audio-card',
  props: {
    favorite: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      metadata: null,
    };
  },
  watch: {
    favorite: {
      immediate: true,
      handler(newVal) {
        this.metadata = null;

        const audio = new Audio(newVal.media.url);
        audio.addEventListener('loadedmetadata', () => {
          this.metadata = { duration: audio.duration };
        });
      },
    },
  },
  methods: {
    formatDuration(time) {
      const minutes = Math.floor(time / 60);
      if (minutes === 0) return 1;
      return minutes;
    },
    onSelect() {
      if (!(this.favorite && this.favorite.media && this.metadata)) return;
      this.$emit('selected', { favorite: this.favorite, metadata: this.metadata });
    },
  },
};
</script>

<style scoped>
.favorite-audio-card {
  border-radius: 15px;
  border: 3px var(--StarlingLightGrey) solid;
}

.favorite-audio-card.loaded {
  cursor: pointer;
}

.favorite-audio-card-content {
  position: relative;
}
</style>
