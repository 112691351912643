var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "favorites-card",
      attrs: { row: "", wrap: "", "pt-3": "", "gap-xs-3": "" }
    },
    [
      _c("v-flex", { attrs: { xs12: "" } }, [
        _c("h1", { staticClass: "starling-h1" }, [
          _vm._v(_vm._s(_vm.$t("app.favorites.title")))
        ])
      ]),
      _c("v-flex", { attrs: { xs12: "" } }, [
        _c("h3", { staticClass: "starling-body" }, [
          _vm._v(_vm._s(_vm.$t("app.favorites.content")))
        ])
      ]),
      !_vm.hasFavorites
        ? [
            _c(
              "v-flex",
              { attrs: { xs12: "" } },
              [_c("empty-favorite-card")],
              1
            ),
            _c(
              "v-flex",
              { attrs: { xs12: "" } },
              [
                _c(
                  "v-layout",
                  { staticClass: "element-instructional" },
                  [
                    _c("v-flex", { attrs: { shrink: "" } }, [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "40",
                            height: "39",
                            viewBox: "0 0 40 39",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              d:
                                "M39.9079 0.196087C39.9079 0.196087 38.2185 4.4952 35.5348 5.79672C32.8511 7.09823 30.9629 6.84765 29.2735 7.82192C27.5841 8.79618 25.0993 10.1968 23.5084 14.9952C23.5084 14.9952 17.8437 3.49475 11.1847 7.59565C3.72648 12.1884 9.39492 19.3953 11.8797 21.2952C11.8797 21.2952 17.0278 25.5943 18.9253 27.195C20.8247 28.7958 23.4081 32.4946 19.3324 38.0952C19.3324 38.0952 15.5559 32.1954 7.46951 26.1946C1.97019 22.1143 -3.62577 12.3941 3.03326 4.89351C9.69228 -2.60703 18.7358 -0.50703 23.2092 4.69342C23.2092 4.69342 27.086 -0.183522 34.1409 0.00534747C41.1958 0.194217 39.9079 0.196087 39.9079 0.196087Z",
                              fill: "url(#instructional-linear)"
                            }
                          }),
                          _c(
                            "defs",
                            [
                              _c(
                                "linearGradient",
                                {
                                  attrs: {
                                    id: "instructional-linear",
                                    x1: "0.00203726",
                                    y1: "19.0489",
                                    x2: "40.0004",
                                    y2: "19.0489",
                                    gradientUnits: "userSpaceOnUse"
                                  }
                                },
                                [
                                  _c("stop", {
                                    attrs: {
                                      "stop-color": "var(--button-gradient-2)"
                                    }
                                  }),
                                  _c("stop", {
                                    attrs: {
                                      offset: "1",
                                      "stop-color": "var(--button-gradient-1)"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ]),
                    _c(
                      "v-flex",
                      { attrs: { shrink: "" } },
                      [
                        _c(
                          "i18n",
                          {
                            staticClass: "starling-instructional",
                            attrs: {
                              path: "app.favorites.empty_content.text",
                              tag: "div"
                            }
                          },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "font-weight-bold",
                                attrs: { to: { name: "videos" } }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t("app.favorites.empty_content.link")
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "", "gap-xs-2": "" } },
                _vm._l(_vm.favorites, function(favorite) {
                  return _c(
                    "v-flex",
                    { key: favorite.id, attrs: { xs12: "" } },
                    [
                      favorite.type === "VIDEO"
                        ? _c("favorite-video-card", {
                            attrs: { favorite: favorite },
                            on: {
                              selected: function($event) {
                                return _vm.onSelected($event)
                              }
                            }
                          })
                        : _vm._e(),
                      favorite.type === "MEDIA" &&
                      favorite.media.type === "AUDIO"
                        ? _c("favorite-audio-card", {
                            attrs: { favorite: favorite },
                            on: {
                              selected: function($event) {
                                return _vm.onSelected($event)
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          ),
      _c(
        "v-dialog",
        {
          attrs: { fullscreen: "", width: "100%" },
          model: {
            value: _vm.displayModal,
            callback: function($$v) {
              _vm.displayModal = $$v
            },
            expression: "displayModal"
          }
        },
        [
          _vm.selected && _vm.displayModal
            ? _c(
                "v-card",
                [
                  _c(
                    "v-card-actions",
                    { staticClass: "pb-0" },
                    [
                      _c("v-spacer"),
                      _c("v-icon", { on: { click: _vm.closeModal } }, [
                        _vm._v("close")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        "align-center": "",
                        "justify-center": "",
                        "pb-5": ""
                      }
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs10: "", sm8: "", xl6: "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "", "gap-xs-3": "" } },
                            [
                              _vm.selected.favorite.type === "VIDEO"
                                ? [
                                    _c("v-flex", { attrs: { xs12: "" } }, [
                                      _c("h3", { staticClass: "starling-h3" }, [
                                        _vm._v(
                                          "  " +
                                            _vm._s(
                                              _vm.selected.favorite.video
                                                .title ||
                                                _vm.selected.vimeoData.title
                                            )
                                        )
                                      ])
                                    ]),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "" } },
                                      [
                                        _c("vimeo-video", {
                                          attrs: {
                                            videoId:
                                              _vm.selected.favorite.video
                                                .vimeoId,
                                            identifier:
                                              _vm.selected.favorite.video
                                                .identifier,
                                            transcript:
                                              _vm.selected.favorite.video
                                                .transcript
                                          },
                                          on: { videoEvent: _vm.onVideoEvent }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                : _vm._e(),
                              _vm.selected.favorite.type === "MEDIA" &&
                              _vm.selected.favorite.media.type === "AUDIO"
                                ? [
                                    _c("v-flex", { attrs: { xs12: "" } }, [
                                      _c(
                                        "h3",
                                        { staticClass: "starling-h3 mb-2" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.selected.favorite.media
                                                  .title
                                              )
                                          )
                                        ]
                                      )
                                    ]),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "" } },
                                      [
                                        _c("audio-player", {
                                          attrs: {
                                            audioURL:
                                              _vm.selected.favorite.media.url,
                                            transcript:
                                              _vm.selected.favorite.media
                                                .transcript,
                                            identifier:
                                              _vm.selected.favorite.media
                                                .identifier
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                : _vm._e(),
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs12: "",
                                    "text-center": "",
                                    "pt-3": ""
                                  }
                                },
                                [
                                  _c(
                                    "primary-button",
                                    {
                                      attrs: { large: "" },
                                      on: { click: _vm.closeModal }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t("common.actions.done")
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }